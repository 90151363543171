import { Button, Dialog, FormControlLabel, styled, Switch, SwitchProps, TextField } from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';
import { useSnackbar, withSnackbar } from "notistack";
import ISwitch from "elements/ISwitch";
import ic_packages from 'icons/ic_packages.svg';


export default function PlanEditor(props : any) {

  /*=============================================
  VARIABLES FROM PROPS
  ============================================= */
  const {
    setLoading, 
    openEditor, 
    setOpenEditor, 
    plan_id,
    loadList
  } = props;


  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */

  const defaultValues: any = {
    plan_id: plan_id? plan_id : "null",
    plan_name: "",
    status: false,
    plan_info:{
      title: "",
      short_description: "",
      description: "",
      prices: {
        monthly: 0,
        yearly: 0
      },
    },
  };
  
  const [formValues, setFormValues] = useState(defaultValues)


  /*=======================================
  GET USER DETAILS IF USER_ID EXISTS
  =======================================*/
  useEffect(() => {
    if (plan_id) {
      axios.get(ProjectConfig.api_url + "/v2/plans/details", {
          params: {
            plan_id: plan_id,
          },
        })
        .then((response) => {
          console.log("formValues res", response.data)
          setFormValues(response.data);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    }
    else{
      setFormValues(defaultValues);
    }
    
  } , [plan_id])




  /*=======================================
  HANDLE INPUT CHANGE
  =======================================*/
  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };


  /*=======================================
  SUBMIT FUNCTION
  =======================================*/
  const handleSubmit = () => {  
    setLoading(true);
    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));
    console.log("formValues", formValues);
    
    axios.post(ProjectConfig.api_url+"/v2/plans/addedit", body,
      {
        headers: {
          // 
        },
      })
      .then((response) => {
        console.log(response.data);

        if(response.data.status === "success"){
          enqueueSnackbar('Success', { variant: 'success' });
          setOpenEditor(false);
          loadList();
          
        }else{
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
          
      })
      .catch((err) => {
        console.log(err.response.data);
      }).
      finally(() => {
        setLoading(false);
      });  
  };



  console.log("formValues", formValues);


  return (
      <>
        <Dialog 
          open={openEditor}
          onClose={() => setOpenEditor(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          PaperProps={{ sx: { borderRadius: "20px" } }}
        >
          <div className="DialogContainer">
              <div className="ModuleWrapper">
                <div className="boxContainerHeader">
                  <div className="dialog_container_title">
                      <h2 className="dialog_title"><img src={ic_packages}/>
                        {plan_id ? "Edit Package" : "Add Package"}
                      </h2>
                      
                      <h3 className="dialog_subtitle">Package</h3>
                  </div>
                </div>

                <div className="dialogFormContainer">
                  <div className='input_group'>

                      <TextField
                        id="plan_name"
                        name="plan_name"
                        label="Package Name"
                        type="text"
                        required
                        fullWidth
                        value={formValues.plan_name}
                        onChange={handleInputChange}
                      />

                      <h2>Prices</h2>

                      <TextField
                        id="monthly_price"
                        name="monthly_price"
                        label="Monthly Price"
                        type="number"
                        required
                        fullWidth
                        value={formValues.plan_info.prices.monthly}
                        onChange={(e: any) => {
                          setFormValues({
                            ...formValues,
                            plan_info: {
                              ...formValues.plan_info,
                              prices: {
                                ...formValues.plan_info.prices,
                                monthly: e.target.value
                              }
                            }

                          })
                        }
                        }
                      />
                          

                      <TextField
                        id="yearly_price"
                        name="yearly_price"
                        label="Annual Price"
                        type="number"
                        required
                        fullWidth
                        value={formValues.plan_info.prices.yearly}
                        onChange={(e: any) => {
                          setFormValues({
                            ...formValues,
                            plan_info: {
                              ...formValues.plan_info,
                              prices: {
                                ...formValues.plan_info.prices,
                                yearly: e.target.value
                              }
                            }
                          });
                        }}
                      />

                      <TextField
                        id="short_description"
                        name="short_description"
                        label="Short Description"
                        type="text"
                        required
                        fullWidth
                        value={formValues.plan_info.short_description}
                        onChange={(e: any) => {
                          setFormValues({
                            ...formValues,
                            plan_info: {
                              ...formValues.plan_info,
                              short_description: e.target.value
                            }
                          });
                        }}
                      />

                      <TextField
                        id="description"
                        name="description"
                        label="Description"
                        type="text"
                        required
                        multiline
                        rows={4}
                        value={formValues.plan_info.description}
                        onChange={(e: any) => {
                          setFormValues({
                            ...formValues,
                            plan_info: {
                              ...formValues.plan_info,
                              description: e.target.value
                            }
                          });
                        }}
                      />

                  </div>

                  <ISwitch
                      label="Status"
                      checked={formValues.status}
                      name="modules"
                      //purple color
                      switchColor="#7B1FA2"
                      onChange={(e: any) => {
                        setFormValues({
                          ...formValues,
                          status: e.target.checked
                        });
                      }} 
                    />
              
                </div>
              </div>
          </div>

          <div className="dialogButtons">

            <Button onClick={handleSubmit} variant="contained" className="button_1">Save Changes</Button>

            <Button
                onClick={() => setOpenEditor(false)}
                variant="contained"
                color="secondary"
                className="button_secondary"
            >
              Cancel
            </Button>

            
          </div>

        </Dialog>
      </>
  );
}
