import axios from 'axios';
import { useEffect, useState } from 'react';
import Box from 'elements/Box';
import { Avatar, AvatarGroup, Button, Chip, CircularProgress, Dialog, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ProjectConfig } from 'Global';
import moment from 'moment';
import AlertDialog from 'elements/AlertDialog';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useSnackbar } from 'notistack';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import 'assets/css/main.css'; 

import ListPagination from 'elements/ListPagination';
import FiltersByTable from 'elements/Filters/FiltersByTable';

export default function MasterLogs(props: any) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { setLoading } = props;

  useEffect(() => {
    setLoading(false);
  }, []);

  const [list, setList] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    total_pages: 1,
    total_items: 0,
  });

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);

  const [openEditor, setOpenEditor] = useState(false);

  const [filtersList, setFiltersList] = useState<any[]>([]);
  const [filtersValues, setFiltersValues] = useState<any>([]);

  /*=========================================================
  GET ACTIVITY LOGS
  =========================================================*/
  const [activityLogs, setActivityLogs] = useState([]);
  useEffect(() => {
    loadList();
  }, [page, filtersValues]);


  const loadList = (download = false) => {
    setLoading(true);
    axios.get(ProjectConfig.api_url + "/v2/stores/activity_logs", {
      params: {
        filters: filtersValues
      }, 
    })
      .then((response) => {
        console.log("activityLogs res", response.data)
        setActivityLogs(response.data.list);
        setPagination(response.data.pagination);
        setFiltersList(response.data.filtersList);

        if (download) {
          const url = window.URL.createObjectURL(new Blob([response.data.csv]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'activity_logs.csv');
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        console.log(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }


  return (
    <div id='MasterLog' className='ModuleContainer'>
      <div className="ModuleWrapper"> 
        <div className="boxContainerHeader">
          <div className="boxContainerTitle">
            <h1>Master Log</h1>
          </div>
          <div className="componentTools">
            <FiltersByTable 
                filters={filtersList} 
                setFilters={setFiltersValues} 
                searchBy={['log']}
            />
            <div>
            <Button 
              variant="contained"
              className="button_3"
              size="small"
              onClick={() => {
                loadList(true);
              }}
            >
                Export CSV
            </Button>
            </div>
          </div>
        </div>

        <div className='boxContainerIn'>
          <div>
            <TableContainer sx={{ width: "100%" }}>
              <Table sx={{ width: "100%" }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Date</TableCell>
                    <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Log</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activityLogs.map((log: any, index:any) => (
                    <TableRow key={index}>
                      <TableCell>{log.date}</TableCell>
                      <TableCell>{log.log}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <ListPagination
              pagination={pagination}
              page={page}
              onChange={(event: any, value: any) => {
                setPage(value);
              }}
              label="Logs"
              style={{ marginTop: "20px", alignSelf: "center" }}
            />
          </div>
        </div>
      </div>


    </div>
  );
};
