import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useSnackbar } from 'notistack';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ProjectConfig } from 'Global';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import 'assets/css/main.css';
import UsersEditor from './UsersEditor';
import EmptyElement from 'elements/EmptyElement';
import TuneIcon from '@mui/icons-material/Tune';
import ListPagination from 'elements/ListPagination';
import FiltersByTable from 'elements/Filters/FiltersByTable';

export default function OrdersList(props: any) {
  const [open, setOpen] = useState(false);
  const [filtersValues, setFiltersValues] = useState<any>([]);
  const { company_id = null, is_popup = false } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { setLoading } = props;

  useEffect(() => {
    setLoading(false);
  }, []);

  const [list, setList] = useState<any[]>([]);
  const [pagination, setPagination] = useState({ total_pages: 1, total_items: 0 });
  const queryParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(localStorage.getItem('page') || "1");
  const [page, setPage] = useState(currentPage);
  const [refreshList, setRefreshList] = useState(0);
  const [openEditor, setOpenEditor] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadList();
  }, [page, filtersValues]);

  
  const loadList = (download=false) => {
    setLoading(true);
    axios.get(ProjectConfig.api_url + "/v2/orders/list", {
      headers: { 'ltkn': localStorage.getItem('ltkn') },
      params: { 
        page: page, 
        filters: filtersValues,
        download
      }
    })
    .then((response) => {
      console.log("orders res", response.data);
      setList(response.data.list);
      setPagination(response.data.pagination);
      setLoading(false);

      if (download) {
        const url = window.URL.createObjectURL(new Blob([response.data.csv]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'orders.csv');
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch((err) => {
      console.log(err.response.data);
    })
    .finally(() => {
      setLoading(false);
    });
    localStorage.setItem('page', page.toString());
  }

  const handleDelete = (user_id: number) => {
    setActiveItem(user_id);
    setOpenAlert(true);
  }

  const [openAlert, setOpenAlert] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);

  return (
    <div id='UsersList' className='ModuleContainer'>
      <div className="ModuleWrapper"> 
        <div className="boxContainerHeader">
          <div className="boxContainerTitle">
            <h1>Orders</h1>
          </div>
          

          <div className="componentTools">
            <div>
              <Button 
                variant="contained"
                className="button_3"
                size="small"
                onClick={() => loadList(true)}
              >
                  Export CSV
              </Button>
            </div>
          </div>
        </div>
        <div id='UsersListIn'>
          <div>
            {list.length !== 0 ? (
              <TableContainer>
                <Table aria-label="sticky table"> 
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Date</TableCell>
                      <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Email</TableCell>
                      <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>First Name</TableCell>
                      <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Last Name</TableCell>
                      <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Address</TableCell>
                      <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>City</TableCell>
                      <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Zip Code</TableCell>
                      <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Country</TableCell>
                      <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Company</TableCell>
                      <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Order</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list.map((item, i) => (
                      <TableRow key={i} hover role="checkbox">
                        <TableCell>{item.date}</TableCell>
                        <TableCell>{item.email}</TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.lastname}</TableCell>
                        <TableCell>{item.address}</TableCell>
                        <TableCell>{item.city}</TableCell>
                        <TableCell>{item.zip_code}</TableCell>
                        <TableCell>{item.country_name}</TableCell>
                        <TableCell>{item.company_name}</TableCell>
                        <TableCell>{item.products}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div className="emptyList">
                <EmptyElement title="No orders" />
              </div>
            )}
            {pagination.total_pages > 1 && (
              <ListPagination 
                pagination={pagination} 
                page={page}  
                onChange={(event: any, value: any) => setPage(value)} 
                label="Orders"
                style={{ marginTop: "20px", alignSelf: "center" }}
              />
            )}
          </div>
        </div>
      </div>
      <UsersEditor
        setLoading={setLoading}
        openEditor={openEditor} 
        setOpenEditor={setOpenEditor}
        user_id={activeItem} 
        loadList={loadList}
        company_id={company_id}
      />
    </div>
  );
};
