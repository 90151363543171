import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ProjectConfig } from 'Global';

import { useSnackbar } from 'notistack';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import 'assets/css/main.css'; 

import ListPagination from 'elements/ListPagination';
import RefundEditor from './RefundEditor';

export default function RefundsList(props: any) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { 
    open, 
    setOpen,
    store_id,
    getDetails
   } = props;



  const [list, setList] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    total_pages: 1,
    total_items: 0,
  });

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);

  /*=========================================================
  GET RECORDS
  =========================================================*/
  useEffect(() => {
    if(open){
      getPayments();
    }
  }, [page,open]);

  const getPayments = async () => {
    axios.get(ProjectConfig.api_url + "/v2/payments/StorePaymentList", {
      params: {
        store_id
      }, 
    })
      .then((response) => {
        console.log("Payments res", response.data)
        setList(response.data.list);
        setPagination(response.data.pagination);
      })
      .catch((err) => {
        console.log(err.response.data);
      })
      .finally(() => {
      });
  }


  const [activeItem, setActiveItem] = useState<any>({});
  const [openEditor, setOpenEditor] = useState(false);


  return (


  <Dialog 
    open={open}
    onClose={() => setOpen(false)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    PaperProps={{ sx: { borderRadius: "20px" } }}
  >
    <div className="DialogContainer">
        <div className="ModuleWrapper">
          <div className="boxContainerHeader">
            <div className="dialog_container_title">
                <h2 className="dialog_title">Payments
                </h2>
                
                <h3 className="dialog_subtitle">List</h3>
            </div>
          </div>

          <div id='MasterLog' className='ModuleContainer'>
            <div className="ModuleWrapper"> 
              

              <div className='boxContainerIn'>
                <div>
                  <TableContainer sx={{ width: "100%" }}>
                    <Table sx={{ width: "100%" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Date</TableCell>
                          <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Amount</TableCell>
                          <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Status</TableCell>
                          <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {list.map((item: any) => (
                          <TableRow>
                            <TableCell>{item.created_at}</TableCell>
                            <TableCell>{item.amount}</TableCell>
                            <TableCell>{item.status}</TableCell>
                            {/* if status active show refund button */}
                            
                              <TableCell>
                              {item.status === 'Paid' && (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    setActiveItem(item);
                                    setOpenEditor(true);
                                  }}
                                >
                                  Refund
                                </Button>
                              )}
                              </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <ListPagination
                    pagination={pagination}
                    page={page}
                    onChange={(event: any, value: any) => {
                      setPage(value);
                    }}
                    label="Logs"
                    style={{ marginTop: "20px", alignSelf: "center" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>

    <RefundEditor
      open = {openEditor} 
      setOpen = {setOpenEditor}
      item = {activeItem}
      store_id = {store_id}
      getPayments = {getPayments}
      getDetails = {getDetails}
    />
  </Dialog>
  );
};
