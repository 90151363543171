import { useEffect, useState } from "react";
import { Radio, RadioGroup, FormControlLabel, Button, Dialog, DialogContent } from '@mui/material';
import { ApiRequest } from "GlobalFunctions";
import Box from "elements/Box";
import PaymentForm from "./Components/PaymentForm";
import PlansRadio from "./Components/PlansRadio";
import PlansButtons from "./Components/PlansButtons";
import { useSnackbar, withSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";

export default function Payment(props: any) {
    const {
        setLoading,
        style = 'default'
    } = props;
    
    const navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [plans, setPlans] = useState([]);
    const [billingCycle, setBillingCycle] = useState('yearly');
    const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
    const [planInfo, setPlanInfo] = useState<any | null>(null);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        getPlans();
    }, []);

    /*============================
    GET PLANS
    ============================*/
    const getPlans = async () => {
        await ApiRequest({
            url: '/plans/getPlans',
            method: 'GET',
            query: {
                store_id: localStorage.getItem('store_id')
            },
            setResponse: (response: any) => {
                setPlans(response.plans);
                setBillingCycle(response.current_plan_type)
                setSelectedPlan(response.current_plan_id);
            }
        });
        setLoading(false);
    };

    /*============================
    HANDLE BILLING CYCLE CHANGE
    ============================*/
    const handleBillingCycleChange = (cycle: string) => {
        setBillingCycle(cycle);
    };

    /*============================
    HANDLE PLAN CHANGE
    ============================*/
    useEffect(() => {
        if (selectedPlan){
            handlePlanChange(selectedPlan);
        }
    }, [billingCycle]);

    /*============================
    FORMAT PRICE
    ============================*/
    const formatPrice = (price: number) => {
        let priceText = "Free";
        if (Number(price) > 0) {
            priceText = `$${price} / Month`
        }
        return priceText;
    };

    /*============================
    HANDLE PLAN CHANGE
    ============================*/
    const handlePlanChange = async (plan_id: any) => {
        setSelectedPlan(plan_id); 

        await ApiRequest({
            url: '/plans/getPlanInfo',
            method: 'GET',
            query: {
                plan_id,
                plan_type: billingCycle,
                store_id: localStorage.getItem('store_id')
            },
            setResponse: (response: any) => {
                setPlanInfo(response);
            }
        });
    };

    /*============================
    UPDATE PLAN
    ============================*/
    const updatePlan = async (formValues = {}, amount = 0) => {
        setLoading(true);

        await ApiRequest({
            url: '/plans/updateStorePlan',
            method: 'POST',
            body: {
                ...formValues,
                amount: amount,
                plan_id: selectedPlan,
                plan_type: billingCycle,
                store_id: localStorage.getItem('store_id'),
                type_of_change: planInfo.data.calculated_price.type_of_change
            },
            setResponse: (response: any) => {
                console.log("UPDATE PLAN RESPONSE ",response);
                if (response.status === "success") {
                    enqueueSnackbar(response.message, { variant: 'success' });
                    setOpenDialog(false);
                    setLoading(false);
                    if(style === 'default'){
                        navigate('/account');
                    }
                    
                } else {
                    enqueueSnackbar(response.message, { variant: 'error' });
                    
                }
            }
        });

        await getPlans();
        
    };

    return (
        <div className="flex_container">
            <Box className={`intent-auth_component ${style === 'default' ? '' : 'custom_payment_container'}`}>
                <div className="input_group">
            
                    <div className="plan_packages_heading-wrapper">
                        <div className="plan-switch_wrapper">
                            <div id="plan_type" className="plan-switch_inner">
                                <div 
                                    plan-select="monthly" 
                                    className={`plan-switch_button ${billingCycle === 'monthly' ? 'is-active' : ''}`}
                                    onClick={() => handleBillingCycleChange('monthly')}
                                >
                                    <div>Pay Monthly</div>
                                </div>
                                <div 
                                    plan-select="yearly" 
                                    className={`plan-switch_button ${billingCycle === 'yearly' ? 'is-active' : ''}`}
                                    onClick={() => handleBillingCycleChange('yearly')}
                                >
                                    <div>Pay Annually</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    {style === 'default' ? (
                        <PlansRadio 
                            plans={plans}
                            billingCycle={billingCycle}
                            selectedPlan={selectedPlan}
                            handlePlanChange={handlePlanChange}
                            activePlan = {selectedPlan}
                        />
                    ) : (
                        <PlansButtons
                            plans={plans}
                            billingCycle={billingCycle}
                            handlePlanChange={handlePlanChange}
                            activePlan = {selectedPlan}
                            setOpenDialog={setOpenDialog}
                        />
                    )}

                    {style === 'default' ? (
                    <PaymentForm 
                        planInfo={planInfo} 
                        updatePlan={updatePlan} 
                        
                    />
                    ) : (
                    <Dialog 
                        open={openDialog} 
                        onClose={() => setOpenDialog(false)}
                    >
                        <DialogContent>
                            <PaymentForm 
                                planInfo={planInfo} 
                                updatePlan={updatePlan} 
                                style="custom"
                                setOpenDialog={setOpenDialog}
                            />
                        </DialogContent>
                    </Dialog>
                    )}
                    
                
                </div>
            </Box>
        </div>
    );
}


