import { Button, Dialog, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { ProjectConfig } from 'Global';

export default function RefundEditor(props: any) {
  /*=============================================
  VARIABLES FROM PROPS
  ============================================= */
  const {
    open,
    setOpen,
    item = {} as any,
    store_id,
    getPayments,
    getDetails
  } = props;

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */

  const defaultValues: any = {
    refund: item.amount || "",
  };

  console.log("Item", item);
  
  const [formValues, setFormValues] = useState(defaultValues);

  useEffect(() => {
    setFormValues({
      refund: item.amount || "",
    });
  }, [item]);

  /*=======================================
  HANDLE INPUT CHANGE
  =======================================*/
  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  /*=======================================
  SUBMIT FUNCTION
  =======================================*/
  const handleSubmit = () => {  
    setOpen(false); // close dialog

    const body = {
      store_subscription_id: item.store_subscription_id,
      refund_amount: formValues.refund,
      store_id: store_id
    };
      
    axios.post(ProjectConfig.api_url + "/v2/payments/refund", body,
      {
        headers: {
        },
      })
      .then((response) => {
        console.log(response.data);

        if(response.data.status === "success"){
          enqueueSnackbar('Refund processed successfully', { variant: 'success' });
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
          
      })
      .catch((err) => {
        console.log(err.response.data);
        enqueueSnackbar('Error processing refund', { variant: 'error' });
      })
      .finally(() => {
        getPayments();
        getDetails();
      });
  };

  return (
    <Dialog 
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{ sx: { borderRadius: "20px" } }}
    >
      <div className="DialogContainer">
        <div className="ModuleWrapper">
          <div className="boxContainerHeader">
            <div className="dialog_container_title">
              <h2 className="dialog_title">Refund</h2>
              <h3 className="dialog_subtitle">Process Refund</h3>
            </div>
          </div>

          <div className="dialogFormContainer">
            <div className='input_group'>
              <TextField
                id="refund"
                name="refund"
                label="Refund Amount"
                type="number"
                required
                fullWidth
                value={formValues.refund}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="dialogButtons"> 
        <Button onClick={handleSubmit} variant="contained" className="button_1">Process Refund</Button>
        <Button
          onClick={() => setOpen(false)}
          variant="contained"
          color="secondary"
          className="button_secondary"
        >
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
