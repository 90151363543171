export const ProjectConfig = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')?{
    api_url: "http://pcapi.osabana.com",
    //api_url: "http://shofi.localhost.com/api/",
    //api_url: "https://api.goshofi.com/api/",
  
    STRIPE_PUBLIC_KEY: "pk_test_51NpZ4yJERu02kDeGMSwl6JVF5BpSByIHzzj3ON1Csk9ekckPViPeR9oLliIp94NjtRjthfzAy00ZAEMCvJeDFHC500aX22iQun",
    GMAP_API_KEY: "AIzaSyDXxN3BGzWBMDCH_k1bKKW2BtUjBeflAFw",
    WEBFLOW_CLIENT_ID: "04e6713602ea2529e63bf5ac314d691dbe5c3e76fc90ec43f909bbd2e4996797",
    /*============================================
    SQUARE
    =============================================*/
    SQUARE_CLIENT_ID: "sandbox-sq0idb-caUgu2AzZvJM3mQ0b4otBw",
    SQUARE_BASE_URL: "https://connect.squareupsandbox.com",
    SQUARE_REDIRECT_URI: "https://j3q5wm2x-3001.use.devtunnels.ms/square/auth"
  }
  :
  {
    api_url: "https://pcapi.osabana.com",
    STRIPE_PUBLIC_KEY: "pk_test_51NpZ4yJERu02kDeGMSwl6JVF5BpSByIHzzj3ON1Csk9ekckPViPeR9oLliIp94NjtRjthfzAy00ZAEMCvJeDFHC500aX22iQun", 
    GMAP_API_KEY: "AIzaSyDXxN3BGzWBMDCH_k1bKKW2BtUjBeflAFw",
    WEBFLOW_CLIENT_ID: "04e6713602ea2529e63bf5ac314d691dbe5c3e76fc90ec43f909bbd2e4996797",
    /*============================================
    SQUARE
    =============================================*/
    SQUARE_CLIENT_ID: "sandbox-sq0idb-caUgu2AzZvJM3mQ0b4otBw",
    SQUARE_BASE_URL: "https://connect.squareup.com",
    SQUARE_REDIRECT_URI: "https://j3q5wm2x-3001.use.devtunnels.ms/square/auth"
  }
  