import { Avatar, Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { ApiRequest } from 'GlobalFunctions';
import { useEffect, useState } from 'react';
import { PiUserCircleLight } from "react-icons/pi";
import { GiExitDoor } from "react-icons/gi";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { set } from 'lodash';
import { color } from '@mui/system';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

export default function TopMenuAdmin(props: any) {
    const { setLoading, data } = props;
    const navigate = useNavigate();


    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);


    const logout = async () => {
        localStorage.removeItem('ltkn');
        localStorage.removeItem('store_id');
        localStorage.removeItem('user_id');
        window.location.href = '/';
    }

    return (
        <>
            <Button
                id="site-select"
                variant="text"
                className='sites_dropdown'
                onClick={(event) => {
                    setOpen(true);
                    setAnchorEl(event.currentTarget);
                }}
                sx={{ border: "none", textTransform: "none" }}
                endIcon={<KeyboardArrowDownIcon />}
            >
                <Avatar sx={{ backgroundColor: "#428EFF" }} src={data.logo}>
                    <PiUserCircleLight size={30} />
                </Avatar>
                <div className='active_site_name'>{data.name} {data.lastname}</div>
            </Button>

            <Menu 
                anchorEl={anchorEl}
                open={open}
                onClose={() => setOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right', // Define que el menú se posicione desde la derecha
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right', // Hace que el menú aparezca hacia la izquierda
                }}
                sx={{ 
                    borderRadius: '20px',
                    marginTop: '40px',
                    padding: '20px',
                }}
                className='sites_dropdown_menu'
            >
                <div className='sites_dropdown_menu_sites'>
                    
                    
                    <MenuItem
                        component={Link}
                        to='/account'
                    >
                        My Account
                    </MenuItem>

                </div>
                <Divider style={{ borderColor: '#ECEBFF' }} />

                <MenuItem 
                    onClick={logout}
                    className='logout'
                >
                    <ListItemText>Logout</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
}
