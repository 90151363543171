import SpeedIcon from '@mui/icons-material/Speed';
import ReceiptIcon from '@mui/icons-material/Receipt';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import WidgetsIcon from '@mui/icons-material/Widgets';
import PersonIcon from '@mui/icons-material/Person';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import MapIcon from '@mui/icons-material/Map';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StarIcon from '@mui/icons-material/Star';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';

import {
    AdminPanelSettings,
    Business,
    Category,
    Inventory2,
    RocketLaunch,
    SupervisorAccount
  } from '@mui/icons-material';
import path from 'path';

export const Permissions = [
    {
        role_id: 2,
        defaultComponent: 'report',
        routes: [
            {
                category: 'Administrador',
                components: [

                    {
                        path: 'report', 
                        component: 'Dashboard',
                        //icon: <SpeedIcon />,
                        label: 'Report',
                    },
                    {
                        path: 'stores',
                        component: 'Stores',
                        label: 'Stores',
                    },
                    {
                        path: 'orders',
                        component: 'Orders',
                        label: 'Order Log',
                    },
                    {
                        path: 'master-log',
                        component: 'MasterLogs',
                        label: 'Master Log',
                    },
                    {
                        path: 'packages',
                        component: 'Plans',
                        label: 'Packages',
                    },
                    {
                        path: 'admin-roles',
                        component: 'Users',
                        label: 'Admin Roles',
                    },
                ]
            },
            {
                category: 'General',
                components: [
                   
                ]
            }
        ]
    },
    {   
        role_id: 1, 
        defaultComponent: 'account',
        routes: [
            {
                category: 'company',
                components: [
                    {
                        path: 'payments',
                        component: 'StoreOrders',
                        label: 'Payments'
                        
                    },
                    {
                        path: 'coupons',
                        component: 'Coupons',
                        label: 'Coupons'
                    },
                    {
                        path: 'shipping',
                        component: 'Shipping',
                        label: 'Shipping'
                    },
                    {
                        path: 'taxes',
                        component: 'Taxes',
                        label: 'Tax Rates'
                    },
                    {
                        path: 'account',
                        component: 'Account',
                        label: 'Account',
                    },
                    {
                        path: 'invoice',
                        component: 'InvoiceEditor',
                        label: 'Invoice'
                    },
                    {
                        path: 'logic',
                        component: 'Logic',
                        label: 'Logic'
                    },
                    
                ]
            },
            
            
        ]
    },
]