import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import InvoicePreview from "./InvoicePreview";
import axios from "axios";
import { ProjectConfig } from "Global";
import PopoverPicker from "./Components/ColorPicker";
import ImageUploader from "../../../elements/ImageUploader";

export default function InvoiceEditor(props: any) {
    const { setLoading } = props;
    const [files, setFiles] = useState<any>([]);
    const defaultFormValues = {
        custom_invoice_id: null,
        image: "",
        address: "",
        state: "",
        country: "Canada",
        zip_code: "",
        email: "",
        color: "#7963ff",
    };

    const [formValues, setFormValues] = useState<any>(defaultFormValues);

    useEffect(() => {
        setLoading(false);
    }, []);

    const handleSubmit = () => {
        setLoading(true);

        const body = new FormData();
        if (files) {
            body.append("image", files);
        }
        body.append("formValues", JSON.stringify(formValues));

        axios.post(ProjectConfig.api_url + "/customInvoices/addEdit", body, {
            headers: {
                "Content-Type": "multipart/form-data",
                store_id: localStorage.getItem("store_id"),
            },
        })
            .then((response) => {
                console.log(response.data);
            })
            .catch((err) => {
                console.log(err.response);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getDetails = () => {
        axios.get(ProjectConfig.api_url + "/customInvoices/details", {
            params: {
                store_id: localStorage.getItem("store_id"),
            },
        })
            .then((response) => {
                if (response.data.status === "success") {
                    setFormValues(response.data.data);
                }
            })
            .catch((err) => {
                console.log(err.response.data);
            });
        setFiles([]);
    };

    useEffect(() => {
        getDetails();
    }, []);

    return (
        <div className="ModuleContainer">
            <div className="ModuleHeader">
                <div className="ModuleTitle">
                    <h1>Custom Invoice</h1>
                </div>
            </div>

            <div id="invoice_editor" className="ModuleContent">
                <div id="invoice_inputs">
                    <div className="invoice_section">
                        <h2>Logo</h2>
                        <div className="drawerImagesContainer">
                            <ImageUploader 
                                image={formValues.image} 
                                setImage={(newImage) => setFormValues({ ...formValues, image: newImage })} 
                                setFiles={setFiles} 
                            />
                        </div>
                        <div className="invoice_logo_text_1">Add or change the company logo on your invoice</div>
                        <div className="invoice_logo_text_2">Recommended Size: 240px x 100px</div>
                    </div>

                    <div className="invoice_section">
                        <h2>Company Information</h2>
                        <div className="input_group">
                            <TextField
                                fullWidth
                                label="Business Address"
                                variant="outlined"
                                value={formValues.address}
                                onChange={(e) => setFormValues({ ...formValues, address: e.target.value })}
                            />
                            <TextField
                                fullWidth
                                label="Business State / Province"
                                variant="outlined"
                                value={formValues.state}
                                onChange={(e) => setFormValues({ ...formValues, state: e.target.value })}
                            />
                            <TextField
                                fullWidth
                                label="Business Country"
                                variant="outlined"
                                value={formValues.country}
                                onChange={(e) => setFormValues({ ...formValues, country: e.target.value })}
                            />
                            <TextField
                                fullWidth
                                label="Zip Code"
                                variant="outlined"
                                value={formValues.zip_code}
                                onChange={(e) => setFormValues({ ...formValues, zip_code: e.target.value })}
                            />
                            <TextField
                                fullWidth
                                label="Support Email"
                                variant="outlined"
                                value={formValues.email}
                                onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                            />
                        </div>
                    </div>

                    <div className="invoice_section">
                        <h2>Brand Color</h2>
                        <span className="invoice_logo_text_1">Select Your Color</span>
                        <PopoverPicker
                            color={formValues.color}
                            onChange={(newColor) => setFormValues({ ...formValues, color: newColor })}
                        />
                    </div>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        sx={{ width: "fit-content" }}
                    >
                        Update Invoice
                    </Button>
                </div>
                <InvoicePreview formValues={formValues} />
            </div>

            <div className="invoice-background pointer-events-off"></div>
        </div>
    );
}
